@font-face {
font-family: 'TT Norms Pro Regular';
font-style: normal;
font-weight: normal;
src: local('TT Norms Pro Regular'), url('TT Norms Pro Regular.woff') format('woff');
}

@font-face {
font-family: 'TT Norms Pro Italic';
font-style: normal;
font-weight: normal;
src: local('TT Norms Pro Italic'), url('TT Norms Pro Italic.woff') format('woff');
}

@font-face {
font-family: 'TT Norms Pro Light';
font-style: normal;
font-weight: normal;
src: local('TT Norms Pro Light'), url('TT Norms Pro Light.woff') format('woff');
}

@font-face {
font-family: 'TT Norms Pro Light Italic';
font-style: normal;
font-weight: normal;
src: local('TT Norms Pro Light Italic'), url('TT Norms Pro Light Italic.woff') format('woff');
}

@font-face {
font-family: 'TT Norms Pro Medium';
font-style: normal;
font-weight: normal;
src: local('TT Norms Pro Medium'), url('TT Norms Pro Medium.woff') format('woff');
}

@font-face {
font-family: 'TT Norms Pro Medium Italic';
font-style: normal;
font-weight: normal;
src: local('TT Norms Pro Medium Italic'), url('TT Norms Pro Medium Italic.woff') format('woff');
}

@font-face {
font-family: 'TT Norms Pro Bold';
font-style: normal;
font-weight: normal;
src: local('TT Norms Pro Bold'), url('TT Norms Pro Bold.woff') format('woff');
}

@font-face {
font-family: 'TT Norms Pro Bold Italic';
font-style: normal;
font-weight: normal;
src: local('TT Norms Pro Bold Italic'), url('TT Norms Pro Bold Italic.woff') format('woff');
}