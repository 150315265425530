.pageSelector {
  :global(.MuiPaginationItem-page.Mui-selected),
  :global(.MuiPaginationItem-page.Mui-selected):hover,
  :global(.MuiPaginationItem-page.Mui-selected.Mui-focusVisible) {
    color: white;
    background-color: rgba(0, 0, 0, 1);;
    transition: background-color 2.5s ease;
  }
  :global(.MuiPaginationItem-page):hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
  }
}
