.buttonPrimary {
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #006BD6;
  height: 44px;
  line-height: 44px;
  color: white;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  position: relative;

  &.noir {
    color: black;
    background-color: white;
    border: 1px solid #000000;
  }

  &.dark {
    color: white;
    background-color: black;
    border: 1px solid #000000;
  }

  &.danger {
    color: #FC4C02;
    background-color: white;
    border: 1px solid #FC4C02;
  }

  &.isDisabled {
    opacity: .65;
    cursor: not-allowed;
  }

  :global(.MuiCircularProgress-root) {
    display: none;
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }

  &.isLoading {
    cursor: wait;
    background: #D9D9D9;
    color: #6E7479;
    opacity: .6;

    :global(.MuiCircularProgress-root) {
      display: inline-block;
    }
  }
}
