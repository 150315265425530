.clickableLink {
  cursor: pointer;

  &:hover {
    color: blue;
    font-size: 16px;
    font-weight: 600;

    transition: font-size .5s ease-in, font-weight .5s ease, font-color 2s;
  }
}

.actionItemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
}
