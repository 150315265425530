.App {
  text-align: center;
  height: 100%;
}

.AppBody {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.pageContent {
  flex-grow: 1;
  background-color: white;
}