.sidebar {
  width: 300px;
  height: 100%;
  transition: width 2s;
  display: flex;
  flex-direction: column;
  align-content: space-between;

  .sidebarHeader {
    cursor: pointer;
    padding: 30px 50px 20px 40px;
    transition: 2s all ease;

    img {
      max-height: 60px;
    }
  }

  .sidebarContent {
    flex-grow: 1;
    color: white;
  }

  .sidebarFooter {
    padding: 20px;
  }
}

.navContainer {
  .navItem {
    padding-left: 50px;
    text-transform: capitalize;
    height: 50px;
    cursor: pointer;
    padding-left: 50px;
    text-align: left;
    height: 60px;

    > span {
      line-height: 60px;
    }

    &.isSelected {
      background-color: #1a1a1a;
      border-left: 5px solid white;
      padding-left: 45px;
      transition: border-width 0.8s ease, background-color 0.5s ease, padding-left 0.8s ease;
    }
  }
}

.sidebar.collapsed {
  width: 50px;

  .sidebarHeader {
    padding-left: 12px;
  }

  .sidebarContent, .sidebarFooter {
    transition: visibility 1.5s, opacity 1.5s linear;
    opacity: 0;
  }
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .userPermissionLabel {
    color: #2ABC3D;
    text-transform: capitalize;
    writing-mode: vertical-lr;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    margin-left: -10px;
    margin-right: 10px;
    font-size: 14px;
  }

  .userProfilePic {
    width: 50px;
    border-radius: 50%;
  }

  .userName {
    color: white;
    flex-grow: 1;
    text-align: left;
    font-size: 16px;
    padding-left: 10px;
  }
}

.logoutBtn {
  width: 100%;
  height: 50px;
  color: white;
  background-color: #1d1d1d;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  margin-top: 20px;

  span {
    padding-left: 5px;
  }
}
