.unauthorizedPage {
  background-color: black;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  display: flex;
  flex-direction: column;
}

.logoutButton {
  height: 50px;
  width: 280px;
  color: black;
  font-weight: bold;
  cursor: pointer;
}
