.modalContainer {
  :global(.MuiDialog-paper) {
    width: 450px;
    padding: 30px;
  }
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;

  button {
    width: 48%;
  }
}