.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;

  h2 {
    display: flex;
    margin-bottom: 5px;
    font-size: 24px;
    font-weight: normal;

    svg {
      margin-right: 16px;
      height: 32px;
      width: 32px;
      cursor: pointer;
    }
  }

  p {
    margin-top: 5px;
    font-size: 12px;
    opacity: 0.6;
  }
}
