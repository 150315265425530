.userInfo {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .userDetail {
    width: 50%;
    display: flex;
    flex-direction: column;

    &:not(:first-child):not(:nth-child(2)){
      margin-top: 20px;
    }
    .userDetailHeader {
      font-size: 14px;
      color: #6E7479;
      line-height: 145%;
      text-transform: uppercase;
    }
    .userDetailBody {
      font-size: 16px;
      line-height: 150%;
      margin-top: 8px;
      text-transform: capitalize;

      svg {
        margin-right: 10px;
      }

      &.green {
        color: #2ABC3D;

        svg path {
          fill: #2ABC3D;
        }
      }
      &.blue {
        color: #006BD6;

        svg path {
          fill: #006BD6;
        }
      }
    }
  }
}
