.dropdownContainer {
  margin-top: 20px;
}

.employeePermissionContainer {
  margin-top: 16px;

  svg {
    width: 16px;
    margin-right: 10px;
  }
}
