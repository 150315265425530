.mobileDevice {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;

    .mobileLogo {
      border: 1px solid #D9D9D9;
      background: #FFFFFF;
      box-sizing: border-box;
      width: 48px;
      height: 48px;
      border-radius: 6px;
      display: flex;
      justify-content: center;

      img {
        width: 20px;
      }
    }
    .titleContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 8px;

      span {
        font-size: 10px;
        line-height: 12px;
        color: #6E7479;
      }
      h4 {
        font-size: 16px;
        line-height: 19px;
        margin: 4px 0px 0px 0px;
        font-weight: normal;
      }
    }
  }

  .body table {
    border-spacing: 0px 16px;

    td:first-child {
      color: #6E7479;
      padding-right: 10px;
    }
    td:last-child::before {
      content: ':';
      padding-right: 2px;
    }
  }
}
