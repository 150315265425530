.therabodyDevice {
  display: flex;
  flex-direction: row;
  padding: 16px;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  max-width: 550px;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 100px;
    border-radius: 6px;
    background-color: #F0F0F0;

    img {
      height: 84px;
    }
  }

  .body {
    width: 100%;
    margin-left: 16px;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    text-align: left;

    table {
      width: 100%;
      border-spacing: 0px 10px;

      td:first-child {
        color: #6E7479;
      }
      td:last-child::before {
        content: ':';
        padding-right: 20px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;

    img {
      cursor: pointer;
      width: 18px;
      height: 18px;
    }
  }
}
