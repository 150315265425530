.searchInputContainer {
  .searchIconContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50px;
    padding-left: 10px;

    svg path {
      color: #747474;
    }
  }

  input {
    height: 50px;
    background-color: #F6F6F6;
    border: none;
    min-width: 220px;
    padding-left: 40px;
    font-size: 16px;
    color: #747474;
    border-radius: 4px;

    &:focus-visible {
      outline: 1px auto #747474;
    }
    @media screen and (min-width: 550px) {
      min-width: 350px;
    }

    @media screen and (min-width: 750px) {
      min-width: 420px;
    }

    @media screen and (min-width: 1100px) {
      min-width: 550px;
    }
  }
}
