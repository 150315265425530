.userDetailPage {
  height: calc(100% - 20px);
  padding: 20px 40px 0px 40px;

  .userDetailBody {
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 45px;
    height: calc(100% - 105px);

    h3 {
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 20px;
    }
  }
}

.informationSection {
  overflow: scroll;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 30px;

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 420px;
    margin-top: 32px;

    > button {
      padding-right: 18px;
      padding-left: 18px;
    }
  }

  .mobileHeader {
    margin-top: 60px;
  }
  .mobileList {
    overflow: scroll;

    > div:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.deviceListContainer {
  overflow: scroll;
  width: 50%;
  display: flex;
  flex-direction: column;

  .deviceList {
    overflow: scroll;
    text-align: center;
  }
}
