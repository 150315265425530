.therabodyEmptyDeviceList {
  display: flex;
  flex-direction: row;
  height: 132px;
  background: #F5F5F5;
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 6px;
  justify-content: center;
  align-items: center;

  img {
    height: 100px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .emptyTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;

    h4 {
      margin: 0;
      font-size: 24px;
      line-height: 145%;
    }
    p {
      margin: 0;
      font-size: 14px;
      line-height: 145%;
    }
  }
}
