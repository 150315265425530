.loadingIcon {
  display: flex;
  align-items: center;

  &.default img {
    max-width: 120px;
  }

  &.big-center {
    justify-content: center;
    padding-top: 30px;

    img {
      max-width: 320px;
    }
  }
}
