body {
  background-color: black;
}

.logoContainer {
  text-align: left;
  padding-left: 40px;
  padding-top: 40px;

  img {
    height: 65px;
  }
}

.landingPage {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.landingBody {
  display: flex;
  flex-grow: 1;
}

.headingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 120px 110px 300px 110px;

  .headingContent {
    max-width: 450px;
  }

  .headingTitle {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .headingDescription {
    line-height: 24px;
  }

  .googleLoginBtn {
    margin-top: 22px;
    width: 280px;
    height: 50px;
    color: #006BD6;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    border: none;

    &:hover {
      border: 2px solid #006BD6;
    }

    img {
      height: 22px;
      width: 22px;
    }

    span {
      padding-left: 22px;
    }
  }
}

.landingImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .landingImage {
    width: 50vw;
    max-width: 550px;
  }
}

// TODO: remove when mock sign in removed
.signinDropdown {
  padding-left: 10px;
  border: 1px solid white;

  div {
    color: white;
    width: 100%;
  }
}
