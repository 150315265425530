.employeesListPage {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.employeesListHeader {
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .headerTopSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .buttonContainer {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }
  }

  .headerBottomSection {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
  }
}

.employeesListBody {
  flex-grow: 1;
  overflow: scroll;
}

.employeesListFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
}
