.roleSpan {
  > div {
    width: 128px;
    height: 32px;
    text-transform: capitalize;
    display: block;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .admin {
    background-color: #EAFFED;
    color: #2ABC3D;
  }
  .viewer {
    background-color: #EBEAFF;
    color: #6C63FF;
  }
  .member {
    background-color: #FFEFE9;
    color: #FC4C02;
  }
}

.actionItemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
}
